import axios from 'axios'
import baseURL from './baseUrl.js'

let instance = axios.create({
    //baseURL: 'http://127.0.0.1:3388',
    baseURL,
    //baseURL: 'http://124.70.81.119',
    //baseURL: 'http://120.46.139.139:82',
    timeout: 120000,
});

// http request 拦截器
instance.interceptors.request.use(
    config => {
        const token = sessionStorage.getItem('token')
        if (token) { // 判断是否存在token，如果存在的话，则每个http header都加上token
            config.headers.Authorization ='Bearer '+token  //请求头加上token
        }
        return config
    },
    err => {
        return Promise.reject(err)
    }
);

// http response 拦截器
instance.interceptors.response.use(
    response => {
        return response.data
    },
    //接口错误状态处理，也就是说无响应时的处理
    error => {
        return Promise.reject(error.response) // 返回接口返回的错误信息
    }
);
export default instance